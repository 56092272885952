<template>
  <div class="modal">
    <div class="modal-title main-block-title">PEP/FATCA</div>
    <div class="modal-close" @click="$emit('close')">
      <icon-close color="#19191C" />
    </div>
    <div class="modal-content">
      <p><b>Politically exposed person (PEP):</b></p>

      <p>A person currently or formerly employed in major public office, including a supreme official of state government, head of a state administrative unit (municipal government), head of government, minister (deputy minister or deputy to the deputy minister, if the relevant country has such a position), state secretary, or other high-level official in government or a state administrative unit (municipal government), parliament deputy or member of an equivalent legislative body, member of the management body (board) of a political party, judge (member of a court institution) in a constitutional court, supreme court or other court instance, member of the board or council of a supreme audit institution, member of the board or council of a central bank, ambassador, charge d’affaires, senior officer of the armed forces, member of the board or council of a state corporation, executive (director, deputy director) or member of the board of an international organisation, or any person holding an equivalent position in such an organisation, within the Republic of Latvia, a member state or third country.</p>

      <p><b>A PEP family member:</b></p>
      <ul>
        <li>Spouse or equivalent. A person is considered an equivalent of a spouse only if the laws of the relevant state provide for such status;</li>
        <li>Child, child of a spouse or equivalent, or their spouse or equivalent;</li>
        <li>Parent, grandparent or grandchild;</li>
        <li>Brother or sister.</li>
      </ul>

      <p>
        <b>A close associate of a PEP</b> – an individual known to have business or other close ties to one of the aforementioned, to be a stockholder or shareholder in the same corporation as one of the aforementioned, or an individual that is the sole owner of a legal entity known to have been established for the actual benefit of one of the aforementioned
      </p>

      <p>
        <b>United States person</b> – any person meeting at least one of the following criteria:
      </p>

      <ul>
        <li>The individual has citizenship or a valid residence permit (Green Card) in the U.S.</li>
        <li>The individual’s tax residence country is the U.S.</li>
        <li>The individual was born in the U.S.</li>
        <li>The legal entity has a phone number with a U.S. country code</li>
        <li>the legal entity is registered in the U.S.</li>
      </ul>

      <p>
        The residential or correspondence address of the individual is in the U.S. (including a P.O. Box in the U.S.)
        A Power of Attorney has been issued to an individual/legal entity with an address in the U.S.
      </p>

    </div>
  </div>
</template>

<script>
import IconClose from "@/assets/icons/IconClose";
export default {
  name: 'PepFatcaModal',
  components: {
    IconClose
  }
}
</script>

<style lang="less" scoped>
  .modal {
    position: absolute;
    left: 0;
    top: 0;
    padding: 40px;
    background: rgba(255, 255, 255, .97);
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;


    &-title {}
    &-content {
      line-height: 1.65em;
      overflow-y: scroll;
      max-height: calc(100% - 50px);

      &::-webkit-scrollbar {
        display: none;
      }

      p {
        margin: 20px 0 10px;
      }

      ul {
        list-style-type: decimal;
        list-style-position: inside;
        margin-bottom: 30px;
      }

      b {
        font-weight: 500;
      }
    }
    &-close {
      position: absolute;
      right: 40px;
      top: 40px;
      cursor: pointer;
    }
  }
</style>
