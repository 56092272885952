<template>
  <!-- @todo: change to svg-->
  <div class="icon-info" :style="styles">i</div>
</template>

<script>
export default {
  name: 'IconInfo',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  },
  computed: {
    styles () {
      return {
        color: this.color,
        borderColor: this.color,
        width: this.size + 'px',
        height: this.size + 'px',
        fontSize: this.size * .65 + 'px',
        lineHeight: this.size + 'px'
      }
    }
  }
}
</script>

<style lang="less">
.icon-info {
  content: '';
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 6px #19191c5e;
  }
}
</style>
