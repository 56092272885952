<template class="grid">
  <div class="person-block">
    <div class="grid">

      <form-group label="Percent" :value="value.percent" :errors="errors.percent" v-if="value.type === 'UBO'">
        <input type="text" data-test="percent" v-model="value.percent" @change="validateAndPatch(['percent'])" class="form-control">
      </form-group>

      <form-group label="First Name" :value="value.first_name" :errors="errors.first_name">
        <input type="text" data-test="first_name" v-model="value.first_name" @change="validate(['first_name'])" class="form-control" autocomplete="given-name">
      </form-group>

      <form-group label="Last Name" :value="value.last_name" :errors="errors.last_name">
        <input type="text" data-test="last_name" v-model="value.last_name" @change="validate(['last_name'])" class="form-control" autocomplete="family-name">
      </form-group>

      <form-group label="Middle Name" :value="value.middle_name" :errors="errors.middle_name">
        <input type="text" data-test="middle_name" v-model="value.middle_name" @change="validate(['middle_name'])" class="form-control" autocomplete="additional-name">
      </form-group>

      <form-group label="Birth Date" :value="value.birthday" :errors="errors.birthday">
        <input type="text" data-test="birthday" v-model="value.birthday" v-mask="'##.##.####'"  @change="validate(['birthday'])" placeholder="DD.MM.YYYY" class="form-control" autocomplete="bday">
      </form-group>

      <form-group label="Gender" :value="value.gender" :errors="errors.gender">
        <v-select data-test="gender" v-model="value.gender" :options="genderList" :reduce="option => option.id" @option:selected="validate(['gender'])" class="form-control"></v-select>
      </form-group>

      <form-group label="Citizenship" :value="value.citizenship_country_id" :errors="errors.citizenship_country_id">
        <v-select data-test="country_id" v-model="value.citizenship_country_id" :options="countriesSorted" :reduce="option => option.id" @option:selected="validate(['citizenship_country_id'])" label="label" class="form-control"></v-select>
      </form-group>
    </div>

    <div class="grid">
      <form-group label="Email" :value="value.email" :errors="errors.email">
        <input type="text" data-test="email" v-model="value.email" @change="validate(['email'])" class="form-control" autocomplete="email">
      </form-group>

      <form-group label="Phone" :value="value.phone" :errors="errors.phone">
        <input type="text" data-test="phone" v-model="value.phone" @change="validate(['phone'])" class="form-control" autocomplete="tel">
      </form-group>
    </div>

<!--    <div class="custom-hr"><span>Please enter address</span></div>-->

    <div class="grid">
      <form-group label="Country" :value="value.country_id" :errors="errors.country_id">
        <v-select data-test="country_id" v-model="value.country_id" :options="countriesSorted" :reduce="option => option.id" @option:selected="validate(['country_id'])" label="label" class="form-control"></v-select>
      </form-group>

      <form-group label="City" :value="value.city" :errors="errors.city">
        <input v-model="value.city" data-test="city" type="text" @change="validate(['city'])" class="form-control" autocomplete="address-level2"/>
      </form-group>

      <form-group label="Street" :value="value.street" :errors="errors.street">
        <input v-model="value.street" data-test="street" type="text" @change="validate(['street'])" class="form-control" autocomplete="address-line1"/>
      </form-group>

      <div class="grid grid-template-3" style="margin-bottom: 0;">
        <form-group label="House" :value="value.house" :errors="errors.house">
          <input v-model="value.house" data-test="house" type="text" @change="validate(['house'])" class="form-control" autocomplete="address-line2"/>
        </form-group>

        <form-group label="Flat" :value="value.apartment" :errors="errors.apartment">
          <input v-model="value.apartment" data-test="apartment" type="text" @change="validate(['apartment'])" class="form-control" autocomplete="address-line3"/>
        </form-group>

        <form-group label="Postal Code" :value="value.postal_code" :errors="errors.postal_code">
          <input v-model="value.postal_code" data-test="postal_code" type="text" @change="validate(['postal_code'])" class="form-control" autocomplete="postal-code"/>
        </form-group>
      </div>
    </div>

    <div class="custom-hr"><span>Please add the required documents</span></div>

    <file-upload
        data-test="identity_document"
        title="Identity Document"
        description="Please add files that help us to identify you. It can be Identity Card or Passport."
        name="files"
        v-model="value.files"
        :types="fileTypes"
        @change="validate(['files'])"
        :errors="errors"
    />

    <div class="custom-hr" />

    <file-upload
        data-test="address_confirmation_files"
        title="Address confirmation"
        description="Please add files for proof of your address. It can be Utility Bill or Bank Statement."
        label-description="(Not older than 3 months)"
        name="address_confirmation_files"
        v-model="value.address_confirmation_files"
        :types="addressConfirmationTypes"
        @change="validate(['address_confirmation_files'])"
        :errors="errors"
    />

    <template v-if="value.type === 'UBO'">
      <div class="custom-hr" />
      <file-upload
          data-test="source_funds_files"
          title="Confirmation of Source of Funds"
          description="Please add files to show us your Source of Funds. It can be dividends, salary, tax declaration, account statement, etc."
          name="source_funds_files"
          v-model="value.source_funds_files"
          :types="fileTypesSourceFunds"
          @change="validate(['source_funds_files'])"
          :errors="errors"
          :custom-input="true"
          placeholder="Enter the title of the document"
      />
    </template>

    <div class="custom-hr" />

    <div class="main-block-title">PEP/FATCA &nbsp; <icon-info color="#19191C" size="15" @click.native="openModal"/></div>

    <div class="grid">
      <div>
        <form-group label="Does the Person qualify as a PEP?" :value="value.is_pep" :errors="errors.is_pep">
          <label data-test="is_pep" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
            <input type="radio" v-model="value.is_pep" :value="item.id" @change="validate(['is_pep'])">
            <span class="form-control">{{ item.label }}</span>
          </label>
        </form-group>
      </div>

      <div>
        <form-group label="Does the Person qualify as a U.S. person?" :value="value.is_us" :errors="errors.is_us">
          <label data-test="is_us" class="form-control-custom" v-for="item in boolAnswers" :key="item.id">
            <input type="radio" v-model="value.is_us" :value="item.id" @change="validate(['is_us'])">
            <span class="form-control">{{ item.label }}</span>
          </label>
        </form-group>
      </div>
    </div>

    <transition name="fade">
      <pep-fatcha-modal v-show="modal" v-on:close="closeModal"/>
    </transition>
  </div>
</template>


<script>
import FormGroup from "@/components/FormGroup";
import IconInfo from '@/assets/icons/IconInfo';
import PepFatchaModal from '@/views/modals/PepFatcaModal';
import FileUpload from "@/components/FileUpload";
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Person',
  components: {
    FormGroup,
    IconInfo,
    PepFatchaModal,
    FileUpload
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    errors: {
      type: [Object, Array]
    }
  },
  data () {
    return {
      /**
       * Open\Close pep-fatcha modal window
       */
      modal: false
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      // Default list data
      genderList: state => state.init.genders,
      boolAnswers: state => state.init.boolAnswers,
      typeList: state => state.init.memberTypes,
      fileTypes: state => state.init.memberFileTypes,
      countries: state => state.countries.list,
      addressConfirmationTypes: state => state.init.addressConfirmationType,
      fileTypesSourceFunds: state => state.init.memberSourceFundsFileType
    }),
    ...mapGetters({
      countriesSorted: 'listSorted'
    }),
    formData () {
      return {
        birthday: this.value.birthday,
        middle_name: this.value.middle_name,
        last_name: this.value.last_name,
        first_name: this.value.first_name,
        gender: this.value.gender,
        citizenship_country_id: this.value.citizenship_country_id,
        email: this.value.email,
        phone: this.value.phone,
        is_pep: this.value.is_pep !== '' ? this.value.is_pep === 'Yes' : null,
        is_us: this.value.is_us !== '' ? this.value.is_us === 'Yes' : null,
        type: this.value.type,
        country_id: this.value.country_id,
        city: this.value.city,
        street: this.value.street,
        postal_code: this.value.postal_code,
        house: this.value.house,
        apartment: this.value.apartment,
        percent: this.value.percent,
        files: this.value.files,
        address_confirmation_files: this.value.address_confirmation_files,
        source_funds_files: this.value.source_funds_files
      }
    }
  },
  methods: {
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    },
    validate (attributes = []) {
      let promise = null

      if (this.value.id) {
        promise = this.$sdk.registration.member.update(this.registrationHash, this.value.id, this.formData, 1)
      } else {
        promise = this.$sdk.registration.member.create(this.registrationHash, this.formData, 1)
      }

      for (let name of ['files', 'address_confirmation_files', 'source_funds_files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][type]')
            attributes.push(name + '[' + i + '][name]')
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      promise.then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    },
    validateAndPatch (attributes = []) {
      if (this.value.id) {
        let promise = this.$sdk.registration.member.update(this.registrationHash, this.value.id, {
          percent: this.formData.percent
        }, 0)

        promise.then(response => {
          for (const attr of attributes) {
            if (response.data[attr] !== undefined) {
              this.$set(this.errors, attr, response.data[attr])
            } else if (this.errors[attr] !== undefined) {
              this.$delete(this.errors, attr)
            }
          }
        })
      } else {
        return this.validate(attributes)
      }
    }
  }
}
</script>

<style lang="less">
  .person-block {
    .modal-close {
      &:hover {
        .icon-close-after,
        .icon-close-before {
          background-color: #FDE46A;
        }
      }
    }
  }
</style>
